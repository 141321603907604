import React from "react";

const Avatar = ({ type = "sofia" }) => {
  return type === "joe" ? (
    <img className="img img-fluid" src="/images/common/joe.svg" />
  ) : (
    <img className="img img-fluid" src="/images/common/sofia.svg" />
  );
};

export default Avatar;
