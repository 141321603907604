import React from "react";
import StepTwo from "../../components/cards/onboardingCard/stepTwo";
import "./style.scss";
import { LiveChatWidget } from '@livechat/widget-react'
import StepThree from "../../components/cards/onboardingCard/stepThree";

const ChooseAssistant = () => {
  return (
    <div className="bg bg-img">
      <div className="mid-card">
        {/* <StepTwo /> */}
        <StepThree />
      </div>
      {/* <LiveChatWidget
      license="14411064"
      visibility="minimized"
    /> */}
    </div>
  );
};

export default ChooseAssistant;
