import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import Avatar from "../../../common/avatar";
import Title from "../../../text/title";
import { setAssistant } from "../../../../actions/user/action";
import "./style.scss";
import { connect } from "react-redux";

const StepTwo = ({ setAssistant }) => {
  const [isMobile, setMobile] = useState(false);

  const onSetAssistant = (assistant) => {
    setAssistant(assistant);
    navigate("/onboarding/user-details");
  };
  useEffect(() => {
    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div className="onboarding-step-two row">
      <div className="col-md-6 col-lg-6 col-sm-6 col-12 r-image flex-mobile-top">
        <div className="top-step-two">
          {isMobile ? (
            <img className="logo" src="/images/common/logo.svg" />
          ) : (
            <img src="/images/common/close.svg" />
          )}
        </div>
        {!isMobile && (
          <img
            className="img img-fluid p-10"
            src="/images/onboarding/step2.svg"
            alt="step2"
          />
        )}
      </div>
      <div className="col-md-6 col-lg-6 col-sm-6 col-12 step-content-step-two bg-pink-step-two">
        <div>
          <Title textAlign="center" text="Welcome to AV," />
          <Title textAlign="center" text="which assiatant would you like?" />
          <div className="row mt-25">
            <span
              onClick={() => onSetAssistant("sofia")}
              className="col-6 d-flex justify-content-center avtar"
            >
              <Avatar type="sofia" />
            </span>
            <span
              onClick={() => onSetAssistant("joe")}
              className="col-6 d-flex justify-content-center avtar"
            >
              <Avatar type="joe" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = () => ({});

export default connect(mapStateToProps, { setAssistant })(StepTwo);
